<template>
  <div
    :class="['jx-carousel',{'jx-carousel--center':center}]"
    :style="styles"
  >
    <el-carousel :height="height" :interval="5000" arrow="never" autoplay>
      <el-carousel-item v-for="item in carouselList" :key="item.id">
        <div
          :style="`background-image: url(${$downloadUrl+item.pictureUrl})`"
          class="jx-carousel_img"
          @click="toCloudClassroom(item)"
        />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { carouselChartList } from '@/api/interviewQuestionBank/interviewQuestion'

/**
 * 轮播图组件
 */
export default {
  name: 'Carousel',
  props: {
    carouselType: {
      type: String,
      default: '学生端'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '230px'
    },
    center: { // 是否采用居中布局
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      carouselList: []
    }
  },
  computed: {
    styles() {
      return {
        width: this.width,
        height: this.height
      }
    }
  },
  created() {
    this.getCarouselList()
  },
  methods: {
    getCarouselList() {
      carouselChartList({ pageSize: 10, pageNum: 1, type: this.carouselType }).then(res => {
        if (res.code === 200) {
          this.carouselList = res.rows
          this.carouselLoading = false
        }
      })
    },
    toCloudClassroom(item) {
      if (item.linkAddress === '#') return
      window.open(item.linkAddress, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.jx-carousel {
  box-sizing: border-box;

  &--center {
    margin: 0 auto;
  }

  &_img {
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100%;
    cursor: pointer;
    border-radius: 10px;
  }
}
</style>
