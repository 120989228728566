<template>
  <!--大转盘活动轮播图-->
  <ActivityDialog ref="ActivityDialog" width="488px">
    <template slot="activityDialog-body">
      <div class="share-warp">
        <div class="header">
          <span>分享给好友</span>
        </div>
        <div class="section">
          <span class="tips">请<span class="tips-col">复制链接</span>分享给好友并注册成功后可增加抽奖次数！</span>
          <div class="link-box">
            <input readonly type="text" :value="linkUrl">
          </div>
          <div class="copy-btn" @click="handleCopy">
            <span>复制链接</span>
          </div>
        </div>
      </div>
    </template>
  </ActivityDialog>
</template>

<script>
import ActivityDialog from '@/components/activity/components/activityDialog'
import { mapState } from 'vuex'

export default {
  name: 'ShareLink',
  components: { ActivityDialog },
  data() {
    return {
      linkUrl: ''
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },
  created() {
    this.linkUrl = process.env.VUE_APP_PC_URL + `index?inviteUserId=${this.userInfo.id}`
  },
  methods: {
    show() {
      this.$refs.ActivityDialog.show(true)
    },
    handleCopy() {
      this.$copyText(this.linkUrl).then(() => {
        this.$message.success('已复制到剪切板')
      }, () => {
        this.$message.error('复制失败请手动选择')
      })
    }
  }
}
</script>

<style scoped lang="less">
.share-warp{
  background-color: #FEFAE8;
  border-radius: 10px;
  .header{
    height: 62px;
    border-bottom: 1px solid #F0E3D5;
    span{
      color: #955632;
      font-size: 21px;
      line-height: 62px;
    }
  }
  .section{
    padding: 19px 31px;
    .tips{
      color: #333333;
      .tips-col{
        color: #955632;
      }
    }
    .link-box{
      margin-top: 20px;
      height: 33px;
      input{
        height: 33px;
        width: 100%;
        border: 1px solid #955632;
        background-color: #fbece1;
        outline: none;
        color: #955632;
      }
    }
    .copy-btn{
      margin: 22px auto 0;
      width: 113px;
      height: 30px;
      background: #DD5C35;
      border-radius: 15px;
      line-height: 30px;
      color: #FFFFFF;
      font-size: 16px;
      cursor: pointer ;
    }
  }
}
</style>
