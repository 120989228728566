<template>
  <!--大转盘活动轮播图-->
  <ActivityDialog ref="ActivityDialog" width="548px">
    <template slot="activityDialog-body">
      <Carousel carousel-type="移动端" height="663px" />
    </template>
  </ActivityDialog>
</template>

<script>
import ActivityDialog from './components/activityDialog'
import Carousel from '@/components/Carousel'

export default {
  name: 'ActivityCarousel',
  components: { ActivityDialog, Carousel },
  methods: {
    show() {
      this.$refs.ActivityDialog.show(true)
    }
  }
}
</script>

<style scoped>

</style>
