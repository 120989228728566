<template>
  <div>
    <el-dialog
        :visible.sync="dialogVisible"
        width="316px"
        :center="true"
        top="30vh"
    >
      <div class="dialog-content-box">{{content}}</div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false" class="dialog-cancel">取 消</el-button>
    <el-button type="primary" @click="confirm" class="dialog-confirm">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "normalDialog",
  props:{
    content:{
      type:String
    }
  },
  data(){
    return {
      dialogVisible:false
    }
  },
  methods:{
    show(){
      this.dialogVisible=true
    },
    confirm(){
      this.$emit('confirm')
    },
  },
}
</script>

<style scoped lang="less">
.dialog-confirm{
  width: 98px;
  height: 40px;
  background: #4C97FD;
  border-radius: 20px;
  border: none;

}
.dialog-cancel{
  width: 98px;
  height: 40px;
  background: #EEF0F2;
  border-radius: 20px;
  margin-right: 15px;
  border: none;
}
/deep/.el-dialog{
  border-radius: 3px;
}
/deep/ .el-dialog__header{
  display: none;
}
.dialog-content-box{
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  text-align: center;
}
///deep/.el-dialog__wrapper {
//  text-align: center;
//  white-space: nowrap;
//  overflow: auto;
//  &:after {
//    content: "";
//    display: inline-block;
//    vertical-align: middle;
//    height: 100%;
//  }
//  .el-dialog {
//    margin: 30px auto !important;
//    display: inline-block;
//    vertical-align: middle;
//    text-align: center;
//    white-space: normal;
//  }
//}
</style>
