<template>
  <ActivityDialog ref="ActivityDialog" width="616px">
    <template slot="activityDialog-body">
      <div class="winning-main">
        <div class="section">
          <div class="header">
            <slot name="header" />
          </div>
          <div class="tips">请及时联系管理员进行兑换！</div>
          <div class="deadline">
            <slot name="deadline" />
          </div>
          <div class="luck-draw">
            <img src="../../assets/images/activity/luckdraw.png" alt="" @click="close">
          </div>
        </div>
      </div>
    </template>
  </ActivityDialog>
</template>

<script>
import ActivityDialog from '@/components/activity/components/activityDialog'

export default {
  name: 'WinningDialog',
  components: { ActivityDialog },
  methods: {
    show() {
      this.$refs.ActivityDialog.show(true)
    },
    close() {
      this.$refs.ActivityDialog.show(false)
    }
  }
}
</script>

<style scoped lang="less">
.winning-main{
  height: 546px;
  overflow: hidden;
  background: url("../../assets/images/activity/winning.png") no-repeat;
  .section{
    height: 100%;
    padding: 128px 143px 95px;
    overflow: hidden;
    .header{
      height: 128px;
    }
    .tips{
      margin: 91px 0 13px;
      color: #FFECC6;
    }
    .luck-draw{
      margin-top: 20px;
      img{
        cursor: pointer;
        height: 41px;
      }
    }
  }
}
</style>
