<template>
  <div class="turnTable-main">
    <!--    左侧logo-->
    <img src="../../assets/images/activity/tianliang-logo.png" alt="" class="logo" @click="$router.push('/')">
    <!--    中间内容-->
    <div class="container">
      <!--    右侧客服-->
      <div class="service-main-box">
        <!--      <div class="customer-service" @click="copyUrl(item)" v-for="(item,index) in app.Dict.dictTree['es_call'].remark">-->
        <div class="customer-service" @click="" v-for="(item,index) in qqList" :key="index+'qq'">
          <a :href="item.url" target="_blank">
            <img src="../../assets/images/activity/qqicon.png" alt="">
            <div class="rectangle-box"><span>客服:</span><span class="service">{{ item.qqNum }}</span></div>
          </a>
        </div>
      </div>
      <div class="title">幸 运 大 转 盘</div>
      <div class="red-packet-box">
        <div class="prize-box">
          <div class="prize-introduce">
            <div class="prize-explain">
              <div class="traverse-line"/>
              <div class="prize-detail">奖品明细</div>
              <div class="traverse-line"/>
            </div>
            <div class="activity" @click="$refs.ActivityRuleAndRecord.show(0)">活动规则</div>
          </div>
        </div>
        <div class="introduce-box">一朝技术在手 天下任你行走</div>
        <!-- 奖品展示-->
        <div class="prize-display">
          <div
              v-for="(item,index) in turntableInfo.activityPrizes.filter(item=>item.defaultFlagNum!==1)"
              v-show="index<6"
              :key="index+'prize'"
              style="width: 33%"
          >
            <div class="img-text" style="">
              <div class="activity-img">
                <img :src="$downloadUrl+item.url" alt="" style="width: 48.6px;height: 48.6px">
              </div>
              <div class="prize-name">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="prize-bottom">
          <!--      走马灯中奖记录-->
          <div class="swiper-record">
            <img src="../../assets/images/activity/horn-icon.png" alt="" class="horn-icon">
            <div style="height: 100%">
              <vueSeamlessScroll v-if="prizeScrollList.length!=0" class="warp" :data="prizeScrollList"
                                 :class-option="defaultOption">
                <ul class="prize-item">
                  <li v-for="(item, index) in prizeScrollList" :key="index+'record'">
                    {{ item.title }}
                  </li>
                </ul>
              </vueSeamlessScroll>
            </div>
          </div>
          <!--转盘-->
          <div class="luckyWheel-box">
            <LuckyWheel
                ref="myLucky"
                width="310px"
                height="310px"
                :prizes="prizes"
                :blocks="blocks"
                :buttons="buttons"
                @start="startCallback"
                @end="endCallback"
            />
            <div class="fill-box" @click="startCallback"></div>
          </div>
          <!--  活动日期-->
          <div class="activity-date">
            活动时间:{{ turntableInfo.beginTime }} ~ {{ turntableInfo.endTime }}
          </div>
          <div class="config" @click="$refs.ActivityRuleAndRecord.show(1)">
            抽奖记录 <img src="../../assets/images/activity/right-arrow.png" alt="">
          </div>
          <img src="../../assets/images/activity/share.png" alt="搜索按钮" class="share-img"
               @click="$refs.ShareLink.show()">
          <div class="share-text">分享给好友并注册成功后增加抽奖次数</div>
        </div>
      </div>
    </div>
    <normal-dialog ref="normaDialog" @confirm="confirm" content="转盘活动暂未开始，是否跳转到首页？"/>
    <ActivityRuleAndRecord ref="ActivityRuleAndRecord" :activityId="turntableInfo.id"/>
    <share-link ref="ShareLink"/>
    <WinningDialog ref="WinningDialog">
      <div slot="header" class="win-msg-box">
        <div v-if="currentPrize.name!=='谢谢参与'">恭喜! 获得{{ currentPrize.name }}</div>
        <div v-else>很遗憾 未中奖</div>
        <img :src="$downloadUrl+currentPrize.url" alt="获得奖品照片">
      </div>
      <div slot="deadline" class="deadLine-box">
        兑换截至时间：{{ turntableInfo.deadLine }}
      </div>
    </WinningDialog>
    <ActivityCarousel ref="ActivityCarousel"></ActivityCarousel>
  </div>
</template>
<script>
import turntableBorder from '../../assets/images/activity/turnplate-border.png'
import lotteryPointer from '../../assets/images/activity/lottery-pointer.png'
import { getRollList, getTurntableDraw, getTurnTableInfo, getTurnTableNum} from '../../api/activity'
import ActivityRuleAndRecord from '../../components/activity/ActivityRuleAndRecord'
import ShareLink from '../../components/activity/ShareLink'
import WinningDialog from "../../components/activity/WinningDialog";
import vueSeamlessScroll from 'vue-seamless-scroll'
import moment from "moment"
import ActivityCarousel from "../../components/activity/ActivityCarousel";
import assetsBgm from '@/assets/choujiangbg.mp3';
import {Howl, Howler} from 'howler';
import NormalDialog from "../../components/activity/normalDialog";

// 状态记录 前后端约定
// turntableInfo.timeStatus  -1未开始 0进行中 1已结束
export default {
  name: 'Turntable',
  components: {
    NormalDialog,
    WinningDialog,
    ShareLink,
    ActivityRuleAndRecord,
    vueSeamlessScroll,
    ActivityCarousel
  },
  data() {
    return {
      //前三项为转盘配置
      blocks: [{padding: '20px', imgs: [{src: turntableBorder, width: '100%', height: '100%'}]}, {
        padding: '8px',
        background: '#feeed4'
      }, {padding: '5px', background: '#ffb969'}],
      buttons: [{
        radius: '35%',
        // background: '#8a9bf3',
        pointer: true,
        fonts: [{text: '抽奖', top: '-28rpx', fontColor: '#fff', fontSize: '25px', fontWeight: 600}, {
          // text: `剩余${luckyDrawNum.value}次`,
          text: `剩余0次`,
          top: '2rpx',
          fontColor: '#fff',
          fontSize: '14rpx'
        }],
        imgs: [{src: lotteryPointer, width: '150%', height: '150%', top: '-150%'}]
      }],
      prizes: [],
      // 活动信息
      turntableInfo: {activityPrizes:[]},
      // 所有奖品信息
      allPrize: '',
      // 当前抽中的奖品信息
      currentPrize: '',
      // 抽奖次数
      luckyDrawNum: 0,
      // 刷新次数计时器
      refreshInterval: '',
      // qq跳转地址
      qqList: [],
      // 跳转地址
      linkUrl: '',
      // 滚动列表数据
      prizeScrollList: [],
      // 更新滚动列表定时器
      refreshPrizeListInterval: '',
      // 抽奖防抖变量
      isLuckyDraw: false,
      // 播放音乐
      sound: {},
      // 提示前往首页对话框显隐
      dialogVisible:false
    }
  },
  async created() {
    this.initPageData()
    this.dealWithQQList()
    this.initSound();
    await this.$store.dispatch('QUERY_ACTIVITY_STATUS').then((res)=>{
      if(res=== false){
        this.$refs.normaDialog.show()
      }
    })
    console.log()
 },
  mounted() {
    if (localStorage.getItem('posterTime') != moment().format('YYYY-MM-DD')) {
      this.$refs.ActivityCarousel.show()
    }
    localStorage.setItem('posterTime', moment().format('YYYY-MM-DD'))
  },
  beforeDestroy() {
    this.sound.stop();
    clearInterval(this.refreshInterval)
  },
  methods: {
    confirm(){
     this.$router.push('/')
    },
    initSound() {
      this.sound = new Howl({
        src: [assetsBgm],
        loop: true,
        preload: true,
      });
      this.sound.play();
    },
    //找到中奖当前物品的最外层大类, 举个例子 现在类型为三项: 单个商品、红包类型、大礼包类型
    // 如果抽中红包类型里的奖品 需拿到最外层红包类型的index,用于大转盘指针停止位置
    findPrizeIndex(list, id) {
      let prizeIndex
      list.forEach((item, index) => {
        //如果 相当直接跳出循环拿到index
        if (item.id === id) {
          prizeIndex = index
          return
        }
        //如果children有数据就去里面寻找,有的话就返回
        if (item.children.length !== 0 && item.children.findIndex(item => item.id === id) !== -1) {
          prizeIndex = index
          return
        }
      })
      return prizeIndex
    },
    // 处理qq客服的请求地址
    dealWithQQList() {
      let es_call = this.$store.state.dict.dictTree.es_call
      let remark = this.$store.state.dict.dictTree.es_call['remark']
      console.log(this.$store.state.dict.dictTree.es_call)
      remark.forEach(function (item, index, arr) {
        if (navigator.userAgent.indexOf('Firefox') !== -1) {
          arr[index] = {url: '/openqq.html?qqNum=' + item, qqNum: es_call.obj[index]}
          // arr[index] = '/openqq.html?qqNum=' + item
        } else {
          arr[index] = {url: 'http://wpa.qq.com/msgrd?v=3&uin=' + item + '&site=qq&menu=yes', qqNum: es_call.obj[index]}
        }
      })
      this.qqList = remark
    },
    // 点击抽奖按钮会触发star回调
    startCallback() {
      if(this.isLuckyDraw)return
      this.isLuckyDraw=true
      if (!this.turntableInfo.id ) {
        this.$message.warning('当前没有进行中的活动，请稍后再来。')
        return
      }
      getTurntableDraw(this.turntableInfo.id).then(res => {
        // 活动未开始或已结束时code码为1001
        if(res.code===1001){
          this.isLuckyDraw=false;
          return
        }
        // 调用抽奖组件的play方法开始游戏
        this.$refs.myLucky.play()
        this.queryDrawNum()
        setTimeout(() => {
          const index = this.allPrize.findIndex(item => item.id === res.data.winningItem)
          //修改中奖信息
          this.currentPrize = this.allPrize[index]
          this.$refs.myLucky.stop(this.findPrizeIndex(this.turntableInfo.activityPrizes, res.data.winningItem))
        }, 3000)
      }).catch(()=>{
        this.isLuckyDraw=false
      })
    },
    // 抽奖结束
    endCallback(prize) {
      this.isLuckyDraw=false
      this.$refs.WinningDialog.show()
    },
    // 初始化数据
    async initPageData() {
      // 获取活动信息
      await getTurnTableInfo().then(res => {
        this.turntableInfo = res.data
        this.dealWithPrizeList(res.data.activityPrizes)
      })
      // 滚动列表查询
      await getRollList(this.turntableInfo.id).then(res => {
        if (res.data != null && res.data.length > 0) {
          this.$nextTick(() => {
            res.data.forEach(item => {
              this.prizeScrollList.push({title: item.userName.substr(0, 1) + `**获得了${item.winningItemName}`});
              // prizeScrollList.value.push({title:});
            })
          })
        }
        console.log(this.prizeScrollList)
      })
      //小于20条数据 开启定时器
      if (this.prizeScrollList.length < 20) {
        this.refreshPrizeListInterval = setInterval(() => {
          //每次进入判断, 如果为大于20条了删除定时器
          if (this.prizeScrollList.length > 20) {
            clearInterval(refreshPrizeList)
            return
          }
          //获取滚动列表接口
          getRollList(this.turntableInfo.id).then(res => {
            if (res.data != null && res.data.length > 0) {
              if(res.data.length <= this.prizeScrollList.length) return
              this.prizeScrollList=[]
               res.data.forEach(item => {
                // if (index > this.prizeScrollList.length - 1) {
                  this.prizeScrollList.push({title: item.userName.substr(0, 1) + `**获得了${item.winningItemName}`});
                // }
              })
            }
            console.log(this.prizeScrollList)
          })
        }, 20000)
      }
      this.queryDrawNum()
      // 处理转盘内信息
      const imgDisplayList = []
      this.turntableInfo.activityPrizes.forEach((item, index) => {
        console.log(this.$downloadUrl + item['turntableUrl'])
        imgDisplayList.push({
          imgs: [{
            src: this.$downloadUrl + item['turntableUrl'],
            // 大转盘个数不同 经测试宽度跟着奖品个数关联最合适
            width: `${this.turntableInfo.activityPrizes.length}0%`,
          }], background: (index + 1) % 2 !== 0 ? '#ffa96e' : '#ffefcf'
        })
      })
      this.prizes = imgDisplayList
      //开启定时任务
      this.startIntervalQueryNum()
    },
    // 开启轮询
    startIntervalQueryNum() {
      if(this.turntableInfo===null)  return
      this.refreshInterval = setInterval(() => {
        this.queryDrawNum()
      }, 20000)
    },
    // 因有大礼包和红包类型，需要将children的数据拿出来
    dealWithPrizeList(list = []) {
      let prizeList = []
      list.forEach(item => {
        if (item.type === '0') {
          prizeList.push(item)
        } else {
          prizeList = prizeList.concat(item.children)
        }
      })
      this.allPrize = prizeList
      console.log(this.allPrize)
    },
    // 查询次数
    queryDrawNum() {
      getTurnTableNum(this.turntableInfo.id).then(res => {
        this.luckyDrawNum = res.data
        this.buttons[0].fonts[1].text = `剩余${res.data}次`
      })
    },

  },
  computed: {
    defaultOption() {
      return {
        step: 0.8,
        limitMoveNum: 2,
        direction: 2,// 0向下 1向上 2向左 3向右
        // limitMoveNum: this.dataList.length,// 开始无缝滚动的数据量 this.dataList.length
        // hoverStop: true,
        // openTouch: false
      }
    }
  },
}
</script>

<style scoped lang="less">
.fill-box{
  width: 100px;
  height: 100px;
  background-color: transparent;
  position: absolute;
  top: 162px;
  left: 221px;
  border-radius: 50%;
  cursor: pointer;
}
.warp {
  width: 100%;
  //height: 100%;
  height: 120px;
  margin: 0 auto;
  overflow: hidden;
  //ul {
  //  list-style: none;
  //  padding: 0;
  //  margin: 0 auto;
  //}
}

.prize-item {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: flex;
  color: #fff;

  li {
    width: 160px;
    height: 38px;
    line-height: 38px;
    margin-right: 10px;
    //line-height: 120px;
    //background-color: #999;
    color: #fff;
    text-align: center;
    font-size: 14px;
  }
}

.win-msg-box {
  div {
    font-size: 22px;
    color: #F0250E;
  }

  img {
    margin-top: 8px;
    height: 93px;
  }
}

.deadLine-box {
  color: #FEEE74;
  font-weight: 500;
  font-size: 14px;
}

.prize-name {
  width: 100%;
  color: #333;
  font-size: 14px;
  font-weight: 600;
  margin-top: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  margin-bottom: 3px;
}

.rectangle-box {
  background-image: url("../../assets/images/activity/yellowLine.png");
  background-size: 100% 100%;
  margin-top: -30px;
  width: 150px;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  display: flex;
  justify-content: center;
  font-size: 14px;

  .service {
    color: red;
    margin-top: 3px;
  }
}

.service-main-box {
  position: absolute;
  right: 50px;
  top: 400px;
  bottom: 0;
}

.customer-service {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 65px;
    height: 65px;
    position: relative;
    //z-index: -1;
  }

  div {
    position: relative;
    z-index: 22;
  }
}

.activity-img {
  width: 58px;
  height: 58px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.share-text {
  text-align: center;
  font-size: 14px;
  color: #9B561E
}

.share-img {
  margin-top: 8px;
  width: 410px;
  cursor: pointer;
}

.config {
  margin-top: 8px;
  width: 121px;
  height: 32px;
  border: 1px solid #FFD39A;
  border-radius: 16px;
  text-align: center;
  color: #FFD399;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    margin-left: 5px;
    width: 15.8px;
    height: 15.8px;
  }
}



.horn-icon {
  margin: 0 15px;
  width: 18.1px;
  height: 17.2px;
}

.prize-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.swiper-record {
  width: 434px;
  height: 38px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, .3);
  border-radius: 30px;
  margin-top: 2vh;
  display: flex;
  align-items: center;
}

.prize-display {
  min-height: 150px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 13%;
  flex-wrap: wrap;

  .img-text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center
  }
}

.logo {
  width: 130px;
  position: fixed;
  top: 29px;
  left: 65px;
  cursor: pointer;
  z-index: 999;
}

.introduce-box {
  margin-bottom: 5px;
  font-size: 12px;
  color: #828282;
  margin-top: 4px;
}

.prize-box {
  margin-top: 20px;
  width: 100%;
}

.traverse-line {
  width: 30px;
  height: 2px;
  border-radius: 15px;
  background-color: red;
}

.prize-introduce {
  position: relative;
  width: 100%;
}

.activity {
  margin-left: auto;
  width: 76px;
  padding: 2px 0;
  font-weight: 600;
  text-align: center;
  border: 1px solid #C06814;
  border-radius: 30px;
  font-size: 14px;
  color: #C06814;
  position: absolute;
  top: -5px;
  right: 90px;
  cursor: pointer;
}

.prize-explain {
  display: flex;
  align-items: center;
  justify-content: center;
}

.prize-detail {
  margin: 0 15px;
  font-size: 15px;
  color: #f45853;
}

.turnTable-main {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 20px;
  //height: 100vh;
  background-image: url("../../assets/images/activity/turntable-bg.png");
  background-size: 100% 100%;
}

.container {
  width: 950px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.title {
  padding-top: 10px;
  font-size: 50px;
  font-family: Swei Fist Leg CJK SC;
  font-weight: 900;
  color: #FFF;
  line-height: 82px;
  text-shadow: 0px 0px 8px rgba(105, 0, 1, 0.5);
}

.red-packet-box {
  margin-top: 10px;
  background-image: url("../../assets/images/activity/red-packet-bg.png");
  width: 541px;
  height: 740px;
  background-size: 100% 100%;
}

.activity-date {
  margin-top: 8px;
  font-size: 14px;
  color: #FFD39A;
  font-weight: 600;
}


</style>
