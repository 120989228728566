<template>
  <el-dialog
    class="activity-dialog-box"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :width="width"
  >
    <div class="icon-box">
      <i class="el-icon-circle-close close-icon" @click="show(false)" />
    </div>
    <div class="body-slot-box">
      <slot name="activityDialog-body" />
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'ActivityDialog',
  props: {
    width: { type: String, default: '50%' }
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    show(isOpen) {
      this.dialogVisible = isOpen
    }
  }
}
</script>

<style scoped lang="less">
.activity-dialog-box{
  ::v-deep .el-dialog__header{
    display: none;
  }
  ::v-deep .el-dialog{
    background-color: transparent!important;
    box-shadow: none;
    &::-webkit-scrollbar{
      width: 0;
      height: 0;
    }
    .el-dialog__body{
      padding: 0!important;
      position: relative;
      img, i{
        cursor: pointer;
      }
      .icon-box{
        position: relative;
        height: 50px;
        .close-icon{
          position: absolute;
          top: 0;
          right: 10px;
          font-size: 30px;
          color: white;
        }
      }
      .body-slot-box{
        padding: 0 40px;
      }
    }
  }
}
</style>
