<template>
  <!--大转盘活动规则和抽奖记录-->
  <ActivityDialog ref="ActivityDialog" width="458px">
    <template slot="activityDialog-body">
      <div class="main">
        <header>
          <div :class="{'tab-select': activeName==='rule'}" @click="tabClick(0)">
            <span>活动规则</span>
          </div>
          <div :class="{'tab-select': activeName==='record'}" @click="tabClick(1)">
            <span>抽奖记录</span>
          </div>
        </header>
        <section v-loading="sectionLoading">
          <div v-if="activeName==='rule'" class="rule-html">
            <div v-html="activityRule"/>
          </div>
          <div v-else class="record-list">
            <div v-if="DrawRecordList.length===0 && sectionLoading" class="write-box">
              <img src="../../assets/images/activity/write-icon.png" alt="">
            </div>
            <div v-else>
              <div v-for="(item, index) in DrawRecordList" :key="index" class="record-item">
                <div class="left">
                  <div class="title">{{ item.winningItemName }}</div>
                  <div class="time">{{ item.drawTime }}</div>
                </div>
                <div v-if="item.defaultFlagNum" style="width: 58px;text-align: center;">
                  — —
                </div>
                <div v-else class="right" :class="{'verification':item.status==='已兑换'}" @click="recordDetail(item)">
                  {{ item.status }}
                </div>
              </div>
            </div>

          </div>
        </section>
      </div>
      <el-dialog
          class="inner-dialog-box"
          width="400px"
          title="兑换码"
          :visible.sync="innerVisible"
          append-to-body
      >
        <div class="reward-code">
          {{ recordDetailData.rewardCode }}
          <img v-if="recordDetailData.status==='已兑换'" src="../../assets/images/activity/redeemed.png" alt="">
        </div>
        <div class="reward-tips">
          请截图后联系工作人员进行兑换！
        </div>
        <span slot="footer" class="dialog-footer">
          <div v-for="(item,index) in es_call" :key="item.id" class="phone-btn" @click="callService(item.url)">
            <img src="../../assets/images/activity/qqicon.png" alt="">
            <div class="phone-text"> 客服{{ index + 1 }} : <span class="phone-num">{{ item.label }}</span></div>
          </div>
        </span>
      </el-dialog>
    </template>
  </ActivityDialog>
</template>

<script>
import ActivityDialog from '@/components/activity/components/activityDialog'
import {getActivityRuleHtml, getDrawRecordList} from '@/api/activity'
import {mapState} from 'vuex'

export default {
  name: 'ActivityRuleAndRecord',
  components: {ActivityDialog},
  props: {
    activityId: {
      type: String,
      default: '1612987507291590656'
    }
  },
  data() {
    return {
      activeName: 'rule',
      sectionLoading: true,
      innerVisible: false,
      activityRule: '',
      DrawRecordList: [],
      recordDetailData: {}
    }
  },
  computed: {
    ...mapState({
      es_call: state => {
        return state.dict.dictTree.es_call['arr'].map(item => {
          let url = ''
          if (navigator.userAgent.indexOf('Firefox') !== -1) {
            url = '/openqq.html?qqNum=' + item.label
          } else {
            url = 'http://wpa.qq.com/msgrd?v=3&uin=' + item.label + '&site=qq&menu=yes'
          }
          return {...item, url}
        })
      }
    })
  },
  methods: {
    /* 打开弹框 参数0/1 tabs的index*/
    show(index) {
      this.activeName = index ? 'record' : 'rule'
      this.$refs.ActivityDialog.show(true)
      this[index ? 'getActivityRecord' : 'getActivityRule']()
    },
    // tab切换
    tabClick(type) {
      this.activeName = type ? 'record' : 'rule'
      this[type ? 'getActivityRecord' : 'getActivityRule']()
    },
    /* 活动规则*/
    getActivityRule() {
      if (!this.activityId) return
      this.sectionLoading = true
      getActivityRuleHtml(this.activityId).then(res => {
        if (res.code === 200) {
          this.sectionLoading = false
          this.activityRule = res.data || '暂无'
        }
      })
    },
    /* 抽奖记录*/
    getActivityRecord() {
      if (!this.activityId) return
      this.sectionLoading = true
      getDrawRecordList().then(res => {
        if (res.code === 200) {
          this.DrawRecordList = res.data.filter(item => item.winningItemName).map(item => {
            return {
              ...item,
              drawTime: this.$moment(item.drawTime).format('YYYY.MM.DD HH:mm')
            }
          })
          this.sectionLoading = false
        }
      })
    },
    /* 兑换码弹框*/
    recordDetail(item) {
      this.recordDetailData = item
      this.innerVisible = true
    },
    callService(url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped lang="less">
.main {
  background-color: #FEFAE8;
  border-radius: 10px;
  color: #955632;

  header {
    height: 62px;
    border-bottom: 1px solid #F0E3D5;
    font-size: 21.22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 83px;

    span {
      cursor: pointer;
    }

    .tab-select {
      color: #DB522A;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -16px;
        left: 27px;
        width: 29px;
        height: 2px;
        background: #DB522A;
      }
    }
  }

  section {
    text-align: left;
    height: 455px;
    box-sizing: border-box;
    padding-bottom: 23px;

    .rule-html {
      padding: 23px 22px 23px 27px;
      height: 100%;
      overflow-y: auto;
      word-break: break-all;
      scrollbar-color: #40a0ff49 transparent;
      scrollbar-width: thin;

      &:hover {
        &::-webkit-scrollbar-thumb {
          background: #40a0ff49;
          border-radius: 10px;
        }
      }

      &::-webkit-scrollbar {
        width: 5px;
        height: 0;
      }

      // 滚动条轨道
      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 2px;
      }

      // 小滑块
      &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 10px;
      }
    }

    .record-list {
      padding: 0 35px;
      height: 100%;
      overflow-y: auto;
      scrollbar-color: #40a0ff49 transparent;
      scrollbar-width: thin;
      font-weight: 600;

      &:hover {
        &::-webkit-scrollbar-thumb {
          background: #40a0ff49;
          border-radius: 10px;
        }
      }

      &::-webkit-scrollbar {
        width: 5px;
        height: 0;
      }

      // 滚动条轨道
      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 2px;
      }

      // 小滑块
      &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 10px;
      }

      .record-item {
        height: 71px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px 0;
        border-bottom: 1px solid #E3D6C7;

        .left {
          .time {
            margin-top: 8px;
            color: #9C7F6F;
            font-size: 12px;
            font-weight: 500;
          }
        }

        .right {
          cursor: pointer;
          border: 1px solid #DB522A;
          border-radius: 8px;
          padding: 3px 7px;
        }

        .verification {
          color: #B86F59;
          border-color: #C99788;
        }
      }
    }
  }
}

.inner-dialog-box {
  margin-top: 10px;

  /deep/ .el-dialog {
    background-color: #FEFAE8;
    border-radius: 10px;
    margin-top: 30vh !important;
  }

  /deep/ .el-dialog__header {
    text-align: center;
    border-radius: 10px 10px 0 0;

    .el-dialog__title {
      color: #955632;
      font-weight: bold;
    }

    .el-dialog__close {
      color: #333333;
    }
  }

  /deep/ .el-dialog__body {
    padding: 10px 26px;
  }

  /deep/ .el-dialog__footer {
    padding: 0;
    text-align: center;

    .dialog-footer {
      padding: 0 16px 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;

      .phone-btn {
        cursor: pointer;
        width: 50%;
        height: 40px;
        background: url("../../assets/images/activity/yellowLine.png") no-repeat -3px 0px;
        background-size: 100% 50px;
        position: relative;

        .phone-text {
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          padding: 16px 19px 0 38px;
          color: #2F2F2F;
          font-size: 12px;

          .phone-num {
            padding-left: 2px;
            color: #D64B20;
          }
        }

        img {
          position: absolute;
          top: 4px;
          left: 0;
          height: 40px;
        }
      }
    }
  }

  .reward-code {
    background: #F8ECE2;
    border: 1px solid #955632;
    border-radius: 4px;
    height: 33px;
    line-height: 33px;
    text-align: center;
    color: #955632;
    position: relative;

    img {
      position: absolute;
      height: 29px;
      top: 1px;
      right: 0;
    }
  }

  .reward-tips {
    text-align: center;
    margin-top: 18px;
    color: #353535;
  }
}
.write-box{
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: 100px;
    cursor: default !important;
  }
}
</style>
