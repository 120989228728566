import request from '@/utils/request'

// 查询轮播图列表
export function carouselChartList(query) {
  return request({
    url: 'api/testQuestion/map/list',
    method: 'get',
    params: query
  })
}

// 查看历史
export function getHistoryData(params) {
  return request({
    url: `api/testQuestion/history/getRecentRecord`,
    method: 'get',
    params
  })
}

// 添加历史纪律
export function addHistory(data) {
  return request({
    url: `api/testQuestion/history`,
    method: 'post',
    data
  })
}

// 热门面试题列表
export function getQuestionBank() {
  return request({
    url: `api/testQuestion/questionBank/hotList?pageNum=1&pageSize=10`,
    method: 'get'
  })
}

// 单个试题详情
export function getInterViewQuestionDetail(id) {
  return request({
    url: `api/testQuestion/questionBank/${id}`,
    method: 'get'
  })
}

// 查询个人题库列表
export function getInterviewQuestion(params) {
  return request({
    url: `api/testQuestion/questionBank/list`,
    method: 'get',
    params
  })
}

// 查询个人课程分类
export function getMyClassification(params) {
  return request({
    url: `api/testQuestion/bank/list`,
    method: 'get',
    params
  })
}

// 添加个人课程分类
export function addMyClassification(data) {
  return request({
    url: `api/testQuestion/bank`,
    method: 'post',
    data
  })
}

// 查询我的收藏
export function myCollectionList(params) {
  return request({
    url: `api/testQuestion/collect/list`,
    method: 'get',
    params
  })
}

// 查询我的书签
export function bookmarkList(params) {
  return request({
    url: `api/testQuestion/bookmark/list`,
    method: 'get',
    params
  })
}

// 添加单个我的书签
export function addBookMark(data) {
  return request({
    url: `api/testQuestion/bookmark`,
    method: 'post',
    data
  })
}

// 设置我的书签
export function batchSetBookMarkList(data) {
  return request({
    url: `api/testQuestion/bookmark/batchOperation`,
    method: 'post',
    data
  })
}

// 知识点和企业标签
export function labelCollection() {
  return request({
    url: `api/testQuestion/label/labelCollection`,
    method: 'get'
  })
}

// 知识点和企业标签-根据课程类型和试题类型获取
export function labelByStuHome(params) {
  return request({
    url: `api/testQuestion/label/stuHomeLabel`,
    method: 'GET',
    params
  })
}

// 知识点和企业标签-根据课程类型和试题类型获取
export function labelByStuPerson(params) {
  return request({
    url: `api/testQuestion/label/collectLabel`,
    method: 'get',
    params
  })
}

// 添加收藏
export function collectAdd(data) {
  return request({
    url: `api/testQuestion/collect`,
    method: 'post',
    data
  })
}

// 取消收藏
export function cancelBookmark(params) {
  return request({
    url: `api/testQuestion/collect/cancelCollection`,
    method: 'get',
    params
  })
}

// 列表删除收藏
export function collectDelete(id) {
  return request({
    url: `api/testQuestion/collect/${id}`,
    method: 'DELETE'
  })
}

// 查询评论
export function getCommentList(params) {
  return request({
    url: `api/testQuestion/comment/list`,
    method: 'get',
    params
  })
}

// 发送评论
export function sendComment(data) {
  return request({
    url: `api/testQuestion/comment`,
    method: 'post',
    data
  })
}

// 发送评论
export function likeOrUnlike(params) {
  return request({
    url: `api/testQuestion/comment/likeOrUnlike`,
    method: 'get',
    params
  })
}
